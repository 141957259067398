import React, { useState, useEffect, forwardRef } from 'react';

//komponen
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Divider } from '@mui/material';

//gambar
import PrintIcon from '@mui/icons-material/Print';
import BorderAllIcon from '@mui/icons-material/BorderAll';

function Laporan_Keuangan(props, ref) {
    //state
    const [tampil_notif, setTampilNotif] = useState(false);
    const [jenis_notif, setJenisNotif] = useState("success");
    const [isi_notif, setIsiNotif] = useState("");    
    const [bulan1, setBulan1] = useState("");
    const [tahun1, setTahun1] = useState("");
    const [bulan2, setBulan2] = useState("");
    const [tahun2, setTahun2] = useState("");

    //fungsi
    useEffect(() => {
        setBulan1((new Date()).getMonth()+1);
        setTahun1((new Date()).getFullYear());
        setBulan2((new Date()).getMonth()+1);
        setTahun2((new Date()).getFullYear());
    }, [props.aplikasi]);

    const ubah_tahun1 = (event) => {
        const re = /^[0-9\b]+$/;
        if (re.test(event.target.value) === false && event.target.value !== "") {
            return;
        }
        setTahun1(event.target.value);        
    }

    const ubah_tahun2 = (event) => {
        const re = /^[0-9\b]+$/;
        if (re.test(event.target.value) === false && event.target.value !== "") {
            return;
        }
        setTahun2(event.target.value);        
    }

    const tutup_notif = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setTampilNotif(false);
    };

    const cetak1 = () => {
        if (bulan1 === "") {
            setJenisNotif("warning");
            setIsiNotif("Bulan wajib diisi");
            setTampilNotif(true);
            document.getElementById("bulan1").focus();
            return;
        }

        if (tahun1 === "") {
            setJenisNotif("warning");
            setIsiNotif("Tahun wajib diisi");
            setTampilNotif(true);
            document.getElementById("tahun1").focus();
            return;
        }

        window.open(props.aplikasi + '/akuntansi_konsolidasi/cetak_neraca.php?bulan=' + bulan1 + '&tahun=' + tahun1);
    }

    const excel1 = () => {
        if (bulan1 === "") {
            setJenisNotif("warning");
            setIsiNotif("Bulan wajib diisi");
            setTampilNotif(true);
            document.getElementById("bulan1").focus();
            return;
        }

        if (tahun1 === "") {
            setJenisNotif("warning");
            setIsiNotif("Tahun wajib diisi");
            setTampilNotif(true);
            document.getElementById("tahun1").focus();
            return;
        }

        window.open(props.aplikasi + '/akuntansi_konsolidasi/excel_neraca.php?bulan=' + bulan1 + '&tahun=' + tahun1);
    }

    const cetak2 = () => {
        if (bulan2 === "") {
            setJenisNotif("warning");
            setIsiNotif("Bulan wajib diisi");
            setTampilNotif(true);
            document.getElementById("bulan2").focus();
            return;
        }

        if (tahun2 === "") {
            setJenisNotif("warning");
            setIsiNotif("Tahun wajib diisi");
            setTampilNotif(true);
            document.getElementById("tahun2").focus();
            return;
        }

        window.open(props.aplikasi + '/akuntansi_konsolidasi/cetak_laba_rugi.php?bulan=' + bulan2 + '&tahun=' + tahun2);
    }

    const excel2 = () => {
        if (bulan2 === "") {
            setJenisNotif("warning");
            setIsiNotif("Bulan wajib diisi");
            setTampilNotif(true);
            document.getElementById("bulan2").focus();
            return;
        }

        if (tahun2 === "") {
            setJenisNotif("warning");
            setIsiNotif("Tahun wajib diisi");
            setTampilNotif(true);
            document.getElementById("tahun2").focus();
            return;
        }

        window.open(props.aplikasi + '/akuntansi_konsolidasi/excel_laba_rugi.php?bulan=' + bulan2 + '&tahun=' + tahun2);
    }

    return (
        <div>
            <Snackbar
                open={tampil_notif}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={tutup_notif}
            >
                <Alert
                    severity={jenis_notif}
                    variant="filled"
                    onClose={tutup_notif}
                    sx={{ width: '100%' }}
                >
                    {isi_notif}
                </Alert>
            </Snackbar>

            <Box
                sx={{
                    backgroundColor: "#eff0f0",
                    width: "100%",
                    height: "100%"
                }}
            >
                <Card
                    sx={{ margin: "10px", padding: "10px" }}
                >
                    <Typography variant="h6">
                        LAPORAN NERACA
                    </Typography>
                    <Divider />
                    <br />

                    <Grid container spacing={1}>
                        <Grid container item>
                            <Grid item xs={4} sm={4} md={3}>
                                <Typography variant="body2">
                                    Bulan
                                </Typography>
                            </Grid>

                            <Grid item xs={8} sm={8} md={9}>
                                <FormControl size="small" fullWidth>
                                    <Select
                                        id="bulan1"
                                        variant="outlined"                                        
                                        value={bulan1}
                                        onChange={(event) => setBulan1(event.target.value)}
                                    >
                                        <MenuItem value="1">Januari</MenuItem>
                                        <MenuItem value="2">Februari</MenuItem>
                                        <MenuItem value="3">Maret</MenuItem>
                                        <MenuItem value="4">April</MenuItem>
                                        <MenuItem value="5">Mei</MenuItem>
                                        <MenuItem value="6">Juni</MenuItem>
                                        <MenuItem value="7">Juli</MenuItem>
                                        <MenuItem value="8">Agustus</MenuItem>
                                        <MenuItem value="9">September</MenuItem>
                                        <MenuItem value="10">Oktober</MenuItem>
                                        <MenuItem value="11">November</MenuItem>
                                        <MenuItem value="12">Desember</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid container item>
                            <Grid item xs={4} sm={4} md={3}>
                                <Typography variant="body2">
                                    Tahun
                                </Typography>
                            </Grid>

                            <Grid item xs={8} sm={8} md={9}>
                                <TextField
                                    id="tahun1"
                                    variant="outlined"
                                    size="small"                                                                        
                                    value={tahun1}
                                    onChange={(e) => ubah_tahun1(e)}
                                />
                            </Grid>
                        </Grid>

                        <Grid container item>
                            <Grid item xs={4} sm={4} md={3}>

                            </Grid>

                            <Grid item xs={8} sm={8} md={9}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<PrintIcon />}
                                    sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#8080FF" }}
                                    onClick={() => cetak1()}
                                >
                                    <Typography variant="body2" noWrap={true}>Cetak</Typography>
                                </Button>

                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<BorderAllIcon />}
                                    sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "green" }}
                                    onClick={() => excel1()}
                                >
                                    <Typography variant="body2" noWrap={true}>Excel</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>

                <Card
                    sx={{ margin: "10px", padding: "10px" }}
                >
                    <Typography variant="h6">
                        LAPORAN LABA / RUGI
                    </Typography>
                    <Divider />
                    <br />

                    <Grid container spacing={1}>
                        <Grid container item>
                            <Grid item xs={4} sm={4} md={3}>
                                <Typography variant="body2">
                                    Bulan
                                </Typography>
                            </Grid>

                            <Grid item xs={8} sm={8} md={9}>
                                <FormControl size="small" fullWidth>
                                    <Select
                                        id="bulan2"
                                        variant="outlined"                                        
                                        value={bulan2}
                                        onChange={(event) => setBulan2(event.target.value)}
                                    >
                                        <MenuItem value="1">Januari</MenuItem>
                                        <MenuItem value="2">Februari</MenuItem>
                                        <MenuItem value="3">Maret</MenuItem>
                                        <MenuItem value="4">April</MenuItem>
                                        <MenuItem value="5">Mei</MenuItem>
                                        <MenuItem value="6">Juni</MenuItem>
                                        <MenuItem value="7">Juli</MenuItem>
                                        <MenuItem value="8">Agustus</MenuItem>
                                        <MenuItem value="9">September</MenuItem>
                                        <MenuItem value="10">Oktober</MenuItem>
                                        <MenuItem value="11">November</MenuItem>
                                        <MenuItem value="12">Desember</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid container item>
                            <Grid item xs={4} sm={4} md={3}>
                                <Typography variant="body2">
                                    Tahun
                                </Typography>
                            </Grid>

                            <Grid item xs={8} sm={8} md={9}>
                                <TextField
                                    id="nama"
                                    variant="outlined"
                                    size="small"                                                                        
                                    value={tahun2}
                                    onChange={(e) => ubah_tahun2(e)}
                                />
                            </Grid>
                        </Grid>

                        <Grid container item>
                            <Grid item xs={4} sm={4} md={3}>

                            </Grid>

                            <Grid item xs={8} sm={8} md={9}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<PrintIcon />}
                                    sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#8080FF" }}
                                    onClick={() => cetak2()}
                                >
                                    <Typography variant="body2" noWrap={true}>Cetak</Typography>
                                </Button>

                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<BorderAllIcon />}
                                    sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "green" }}
                                    onClick={() => excel2()}
                                >
                                    <Typography variant="body2" noWrap={true}>Excel</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>
            </Box>
        </div>
    );
}

export default forwardRef(Laporan_Keuangan);