//utama
import React, { useState, useEffect } from 'react';
import { Routes, Route, Link, Navigate } from 'react-router-dom';

//komponen
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

//form
import Beranda from './beranda';
import Jurnal from '../Akuntansi Pusat/jurnal';
import Favorit from '../Akuntansi Pusat/favorit';
import GeneralLedger from '../Akuntansi Pusat/general_ledger';
import DaftarTransaksi from '../Akuntansi Pusat/daftar_transaksi';
import LapKeuangan from '../Akuntansi Pusat/laporan_keuangan';
import TrackingGL from '../Akuntansi Pusat/tracking_gl';
import DaftarTransaksiGkr from '../Akuntansi GKR/daftar_transaksi';
import LapKeuanganGkr from '../Akuntansi GKR/laporan_keuangan';
import TrackingGLGkr from '../Akuntansi GKR/tracking_gl';
import DaftarTransaksiCarwash from '../Akuntansi Carwash/daftar_transaksi';
import LapKeuanganCarwash from '../Akuntansi Carwash/laporan_keuangan';
import TrackingGLCarwash from '../Akuntansi Carwash/tracking_gl';
import DaftarTransaksiGym from '../Akuntansi Gym/daftar_transaksi';
import LapKeuanganGym from '../Akuntansi Gym/laporan_keuangan';
import TrackingGLGym from '../Akuntansi Gym/tracking_gl';
import LapKeuanganKonsolidasi from '../Akuntansi Konsolidasi/laporan_keuangan';
import Belanja from './belanja';
import PenyesuaianStok from './penyesuaian_stok';
import LapPenjualan from '../Laporan/transaksi';
import LapMetodeBayar from '../Laporan/metode_bayar';
import LapBelanjaBahan from '../Laporan/belanja';
import LapKehadiran from '../Laporan/kehadiran';
import LapLayanan from '../Laporan/layanan';
import LapPengguna from '../Laporan/pengguna';
import LapKaryawan from '../Laporan/karyawan';
import Pelanggan from './pelanggan';
import Layanan from './layanan';
import Pengguna from './pengguna';
import Karyawan from './karyawan';
import MetodeBayar from './metode_bayar';
import TerimaSaldo from './terima_saldo';
import Eom from './eom';
import Pengaturan from './pengaturan';

//gambar
import MenuIcon from '@mui/icons-material/Menu';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import MoneyIcon from '@mui/icons-material/Money';
import AddTaskIcon from '@mui/icons-material/AddTask';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

function Utama(props) {
  //state
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [menu, setMenu] = useState("Beranda");
  const [submenu_laporan, setSubmenuLaporan] = useState(false);
  const [submenu_akuntansi_pusat, setSubmenuAkuntansiPusat] = useState(false);
  const [submenu_akuntansi_gkr, setSubmenuAkuntansiGkr] = useState(false);
  const [submenu_akuntansi_carwash, setSubmenuAkuntansiCarwash] = useState(false);
  const [submenu_akuntansi_gym, setSubmenuAkuntansiGym] = useState(false);
  const [submenu_akuntansi_konsolidasi, setSubmenuAkuntansiKonsolidasi] = useState(false);
  const [submenu_transaksi, setSubmenuTransaksi] = useState(false);
  const [submenu_master, setSubmenuMaster] = useState(false);
  const [tampil_dialog_keluar, setTampilDialogKeluar] = useState(false);

  //fungsi
  // useEffect(() => {
  //   setMenu(localStorage.getItem("menu"));

  //   if (localStorage.getItem("submenu_laporan") === "true") {
  //     setSubmenuLaporan(true);
  //   }
  //   else {
  //     setSubmenuLaporan(false);
  //   }

  //   if (localStorage.getItem("submenu_akuntansi_pusat") === "true") {
  //     setSubmenuAkuntansiPusat(true);
  //   }
  //   else {
  //     setSubmenuAkuntansiPusat(false);
  //   }

  //   if (localStorage.getItem("submenu_transaksi") === "true") {
  //     setSubmenuTransaksi(true);
  //   }
  //   else {
  //     setSubmenuTransaksi(false);
  //   }

  //   if (localStorage.getItem("submenu_master") === "true") {
  //     setSubmenuMaster(true);
  //   }
  //   else {
  //     setSubmenuMaster(false);
  //   }
  // }, [props.aplikasi]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const pindah_menu = (varmenu) => {
    localStorage.setItem("menu", varmenu);
    localStorage.setItem("submenu_laporan", false);
    localStorage.setItem("submenu_akuntansi_pusat", false);
    localStorage.setItem("submenu_akuntansi_gkr", false);
    localStorage.setItem("submenu_akuntansi_carwash", false);
    localStorage.setItem("submenu_akuntansi_gym", false);
    localStorage.setItem("submenu_akuntansi_konsolidasi", false);
    localStorage.setItem("submenu_transaksi", false);
    localStorage.setItem("submenu_master", false);
    setMenu(varmenu);
    setSubmenuLaporan(false);
    setSubmenuAkuntansiPusat(false);
    setSubmenuAkuntansiGkr(false);
    setSubmenuAkuntansiCarwash(false);
    setSubmenuAkuntansiGym(false);
    setSubmenuAkuntansiKonsolidasi(false);
    setSubmenuTransaksi(false);
    setSubmenuMaster(false);
  }

  const drawer = (
    <div>
      {/* <Toolbar />
      <Divider /> */}
      <List>
        <Link to="/beranda" style={{ textDecoration: "none", color: "black" }}
          onClick={() => {
            pindah_menu("Beranda");
          }}
        >
          <ListItem selected={menu === "Beranda"}>
            <ListItemIcon><HomeOutlinedIcon style={{ color: "#fe9400" }} /></ListItemIcon>
            <ListItemText>Beranda</ListItemText>
          </ListItem>
        </Link>

        {/* <ListItem onClick={() => setSubmenuLaporan(!submenu_laporan)}>
          <ListItemIcon><DescriptionOutlinedIcon style={{ color: "blue" }} /></ListItemIcon>
          <ListItemText>Laporan</ListItemText>
          {submenu_laporan === true ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={submenu_laporan} timeout="auto" unmountOnExit>
          <Link to="/laporan_penjualan" style={{ textDecoration: "none", color: "black" }}
            onClick={() => {
              
            }}
          >
            <ListItem selected={menu === "Laporan Penjualan"} sx={{ pl: 9 }}>
              <ListItemText>Penjualan</ListItemText>
            </ListItem>
          </Link>

          <Link to="/laporan_metode_bayar" style={{ textDecoration: "none", color: "black" }}
            onClick={() => {
              
            }}
          >
            <ListItem selected={menu === "Laporan Metode Bayar"} sx={{ pl: 9 }}>
              <ListItemText>Metode Bayar</ListItemText>
            </ListItem>
          </Link>

          <Link to="/laporan_belanja" style={{ textDecoration: "none", color: "black" }}
            onClick={() => {
              
            }}
          >
            <ListItem selected={menu === "Laporan Belanja"} sx={{ pl: 9 }}>
              <ListItemText>Belanja</ListItemText>
            </ListItem>
          </Link>

          <Link to="/laporan_kehadiran" style={{ textDecoration: "none", color: "black" }}
            onClick={() => {
              
            }}
          >
            <ListItem selected={menu === "Laporan Kehadiran"} sx={{ pl: 9 }}>
              <ListItemText>Kehadiran</ListItemText>
            </ListItem>
          </Link>

          <Link to="/laporan_layanan" style={{ textDecoration: "none", color: "black" }}
            onClick={() => {
              
            }}
          >
            <ListItem selected={menu === "Laporan Layanan"} sx={{ pl: 9 }}>
              <ListItemText>Layanan</ListItemText>
            </ListItem>
          </Link>

          <Link to="/laporan_pengguna" style={{ textDecoration: "none", color: "black" }}
            onClick={() => {
              
            }}
          >
            <ListItem selected={menu === "Laporan Pengguna"} sx={{ pl: 9 }}>
              <ListItemText>Pengguna</ListItemText>
            </ListItem>
          </Link>

          <Link to="/laporan_karyawan" style={{ textDecoration: "none", color: "black" }}
            onClick={() => {
              
            }}
          >
            <ListItem selected={menu === "Laporan Karyawan"} sx={{ pl: 9 }}>
              <ListItemText>Karyawan</ListItemText>
            </ListItem>
          </Link>
        </Collapse> */}

        <ListItem onClick={() => setSubmenuAkuntansiPusat(!submenu_akuntansi_pusat)}>
          <ListItemIcon><AccountBalanceWalletIcon style={{ color: "green" }} /></ListItemIcon>
          <ListItemText>Akuntansi Pusat</ListItemText>
          {submenu_akuntansi_pusat === true ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={submenu_akuntansi_pusat} timeout="auto" unmountOnExit>
          <Link to="/jurnal" style={{ textDecoration: "none", color: "black" }}
            onClick={() => {
              pindah_menu("Input Jurnal");
            }}
          >
            <ListItem selected={menu === "Input Jurnal"} sx={{ pl: 9 }}>
              <ListItemText>Input Jurnal</ListItemText>
            </ListItem>
          </Link>

          <Link to="/favorit" style={{ textDecoration: "none", color: "black" }}
            onClick={() => {
              pindah_menu("Jurnal Favorit");
            }}
          >
            <ListItem selected={menu === "Jurnal Favorit"} sx={{ pl: 9 }}>
              <ListItemText>Jurnal Favorit</ListItemText>
            </ListItem>
          </Link>

          <Link to="/general_ledger" style={{ textDecoration: "none", color: "black" }}
            onClick={() => {
              pindah_menu("General Ledger");
            }}
          >
            <ListItem selected={menu === "General Ledger"} sx={{ pl: 9 }}>
              <ListItemText>General Ledger</ListItemText>
            </ListItem>
          </Link>

          <Link to="/daftar_transaksi_pusat" style={{ textDecoration: "none", color: "black" }}
            onClick={() => {
              pindah_menu("Daftar Transaksi Pusat");
            }}
          >
            <ListItem selected={menu === "Daftar Transaksi Pusat"} sx={{ pl: 9 }}>
              <ListItemText>Daftar Transaksi</ListItemText>
            </ListItem>
          </Link>

          <Link to="/laporan_keuangan_pusat" style={{ textDecoration: "none", color: "black" }}
            onClick={() => {
              pindah_menu("Laporan Keuangan Pusat");
            }}
          >
            <ListItem selected={menu === "Laporan Keuangan Pusat"} sx={{ pl: 9 }}>
              <ListItemText>Lap. Keuangan</ListItemText>
            </ListItem>
          </Link>

          <Link to="/tracking_gl_pusat" style={{ textDecoration: "none", color: "black" }}
            onClick={() => {
              pindah_menu("Tracking GL Pusat");
            }}
          >
            <ListItem selected={menu === "Tracking GL Pusat"} sx={{ pl: 9 }}>
              <ListItemText>Tracking GL</ListItemText>
            </ListItem>
          </Link>
        </Collapse>

        <ListItem onClick={() => setSubmenuAkuntansiGkr(!submenu_akuntansi_gkr)}>
          <ListItemIcon><AccountBalanceWalletIcon style={{ color: "green" }} /></ListItemIcon>
          <ListItemText>Akuntansi GKR</ListItemText>
          {submenu_akuntansi_gkr === true ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={submenu_akuntansi_gkr} timeout="auto" unmountOnExit>
          <Link to="/daftar_transaksi_gkr" style={{ textDecoration: "none", color: "black" }}
            onClick={() => {
              pindah_menu("Daftar Transaksi GKR");
            }}
          >
            <ListItem selected={menu === "Daftar Transaksi GKR"} sx={{ pl: 9 }}>
              <ListItemText>Daftar Transaksi</ListItemText>
            </ListItem>
          </Link>

          <Link to="/laporan_keuangan_gkr" style={{ textDecoration: "none", color: "black" }}
            onClick={() => {
              pindah_menu("Laporan Keuangan GKR");
            }}
          >
            <ListItem selected={menu === "Laporan Keuangan GKR"} sx={{ pl: 9 }}>
              <ListItemText>Lap. Keuangan</ListItemText>
            </ListItem>
          </Link>

          <Link to="/tracking_gl_gkr" style={{ textDecoration: "none", color: "black" }}
            onClick={() => {
              pindah_menu("Tracking GL GKR");
            }}
          >
            <ListItem selected={menu === "Tracking GL GKR"} sx={{ pl: 9 }}>
              <ListItemText>Tracking GL</ListItemText>
            </ListItem>
          </Link>
        </Collapse>

        <ListItem onClick={() => setSubmenuAkuntansiCarwash(!submenu_akuntansi_carwash)}>
          <ListItemIcon><AccountBalanceWalletIcon style={{ color: "green" }} /></ListItemIcon>
          <ListItemText>Akuntansi Carwash</ListItemText>
          {submenu_akuntansi_carwash === true ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={submenu_akuntansi_carwash} timeout="auto" unmountOnExit>
          <Link to="/daftar_transaksi_carwash" style={{ textDecoration: "none", color: "black" }}
            onClick={() => {
              pindah_menu("Daftar Transaksi Carwash");
            }}
          >
            <ListItem selected={menu === "Daftar Transaksi Carwash"} sx={{ pl: 9 }}>
              <ListItemText>Daftar Transaksi</ListItemText>
            </ListItem>
          </Link>

          <Link to="/laporan_keuangan_carwash" style={{ textDecoration: "none", color: "black" }}
            onClick={() => {
              pindah_menu("Laporan Keuangan Carwash");
            }}
          >
            <ListItem selected={menu === "Laporan Keuangan Carwash"} sx={{ pl: 9 }}>
              <ListItemText>Lap. Keuangan</ListItemText>
            </ListItem>
          </Link>

          <Link to="/tracking_gl_carwash" style={{ textDecoration: "none", color: "black" }}
            onClick={() => {
              pindah_menu("Tracking GL Carwash");
            }}
          >
            <ListItem selected={menu === "Tracking GL Carwash"} sx={{ pl: 9 }}>
              <ListItemText>Tracking GL</ListItemText>
            </ListItem>
          </Link>
        </Collapse>

        <ListItem onClick={() => setSubmenuAkuntansiGym(!submenu_akuntansi_gym)}>
          <ListItemIcon><AccountBalanceWalletIcon style={{ color: "green" }} /></ListItemIcon>
          <ListItemText>Akuntansi Gym</ListItemText>
          {submenu_akuntansi_gym === true ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={submenu_akuntansi_gym} timeout="auto" unmountOnExit>
          <Link to="/daftar_transaksi_gym" style={{ textDecoration: "none", color: "black" }}
            onClick={() => {
              pindah_menu("Daftar Transaksi Gym");
            }}
          >
            <ListItem selected={menu === "Daftar Transaksi Gym"} sx={{ pl: 9 }}>
              <ListItemText>Daftar Transaksi</ListItemText>
            </ListItem>
          </Link>

          <Link to="/laporan_keuangan_gym" style={{ textDecoration: "none", color: "black" }}
            onClick={() => {
              pindah_menu("Laporan Keuangan Gym");
            }}
          >
            <ListItem selected={menu === "Laporan Keuangan Gym"} sx={{ pl: 9 }}>
              <ListItemText>Lap. Keuangan</ListItemText>
            </ListItem>
          </Link>

          <Link to="/tracking_gl_gym" style={{ textDecoration: "none", color: "black" }}
            onClick={() => {
              pindah_menu("Tracking GL Gym");
            }}
          >
            <ListItem selected={menu === "Tracking GL Gym"} sx={{ pl: 9 }}>
              <ListItemText>Tracking GL</ListItemText>
            </ListItem>
          </Link>
        </Collapse>

        <ListItem onClick={() => setSubmenuAkuntansiKonsolidasi(!submenu_akuntansi_konsolidasi)}>
          <ListItemIcon><AccountBalanceWalletIcon style={{ color: "green" }} /></ListItemIcon>
          <ListItemText>Akuntansi Konsolidasi</ListItemText>
          {submenu_akuntansi_konsolidasi === true ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={submenu_akuntansi_konsolidasi} timeout="auto" unmountOnExit>
          <Link to="/laporan_keuangan_konsolidasi" style={{ textDecoration: "none", color: "black" }}
            onClick={() => {
              pindah_menu("Laporan Keuangan Konsolidasi");
            }}
          >
            <ListItem selected={menu === "Laporan Keuangan Konsolidasi"} sx={{ pl: 9 }}>
              <ListItemText>Lap. Keuangan</ListItemText>
            </ListItem>
          </Link>
        </Collapse>

        {/* <ListItem onClick={() => setSubmenuTransaksi(!submenu_transaksi)}>
          <ListItemIcon><ShoppingBasketIcon style={{ color: "#baa8e6" }} /></ListItemIcon>
          <ListItemText>Transaksi</ListItemText>
          {submenu_transaksi === true ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={submenu_transaksi} timeout="auto" unmountOnExit>
          <Link to="/belanja" style={{ textDecoration: "none", color: "black" }}
            onClick={() => {

            }}
          >
            <ListItem selected={menu === "Belanja"} sx={{ pl: 9 }}>
              <ListItemText>Belanja</ListItemText>
            </ListItem>
          </Link>

          <Link to="/penyesuaian_stok" style={{ textDecoration: "none", color: "black" }}
            onClick={() => {
              
            }}
          >
            <ListItem selected={menu === "Penyesuaian Stok"} sx={{ pl: 9 }}>
              <ListItemText>Penyesuaian Stok</ListItemText>
            </ListItem>
          </Link>
        </Collapse> */}

        <ListItem onClick={() => setSubmenuMaster(!submenu_master)}>
          <ListItemIcon><FitnessCenterIcon style={{ color: "red" }} /></ListItemIcon>
          <ListItemText>Master</ListItemText>
          {submenu_master === true ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={submenu_master} timeout="auto" unmountOnExit>
          {/* <Link to="/pelanggan" style={{ textDecoration: "none", color: "black" }}
            onClick={() => {
              
            }}
          >
            <ListItem selected={menu === "Pelanggan"} sx={{ pl: 9 }}>
              <ListItemText>Pelanggan</ListItemText>
            </ListItem>
          </Link>

          <Link to="/layanan" style={{ textDecoration: "none", color: "black" }}
            onClick={() => {
              
            }}
          >
            <ListItem selected={menu === "Layanan"} sx={{ pl: 9 }}>
              <ListItemText>Layanan</ListItemText>
            </ListItem>
          </Link> */}

          <Link to="/pengguna" style={{ textDecoration: "none", color: "black" }}
            onClick={() => {
              pindah_menu("Pengguna");
            }}
          >
            <ListItem selected={menu === "Pengguna"} sx={{ pl: 9 }}>
              <ListItemText>Pengguna</ListItemText>
            </ListItem>
          </Link>

          <Link to="/karyawan" style={{ textDecoration: "none", color: "black" }}
            onClick={() => {
              pindah_menu("Karyawan");
            }}
          >
            <ListItem selected={menu === "Karyawan"} sx={{ pl: 9 }}>
              <ListItemText>Karyawan</ListItemText>
            </ListItem>
          </Link>

          {/* <Link to="/metode_bayar" style={{ textDecoration: "none", color: "black" }}
            onClick={() => {
              
            }}>
            <ListItem selected={menu === "Metode Bayar"} sx={{ pl: 9 }}>
              <ListItemText>Metode Bayar</ListItemText>
            </ListItem>
          </Link> */}
        </Collapse>
      </List>
      <Divider />
      <List>
        <Link to="/terima_saldo" style={{ textDecoration: "none", color: "black" }}
          onClick={() => {
            pindah_menu("Terima Saldo");
          }}
        >
          <ListItem selected={menu === "Terima Saldo"}>
            <ListItemIcon><MoneyIcon style={{ color: "green" }} /></ListItemIcon>
            <ListItemText>Terima Saldo</ListItemText>
          </ListItem>
        </Link>
        <Link to="/eom" style={{ textDecoration: "none", color: "black" }}
          onClick={() => {
            pindah_menu("Proses EOM");
          }}
        >
          <ListItem selected={menu === "Proses EOM"}>
            <ListItemIcon><AddTaskIcon style={{ color: "purple" }} /></ListItemIcon>
            <ListItemText>Proses EOM</ListItemText>
          </ListItem>
        </Link>
        <Link to="/pengaturan" style={{ textDecoration: "none", color: "black" }}
          onClick={() => {
            pindah_menu("Pengaturan");
          }}
        >
          <ListItem selected={menu === "Pengaturan"}>
            <ListItemIcon><SettingsOutlinedIcon style={{ color: "black" }} /></ListItemIcon>
            <ListItemText>Pengaturan</ListItemText>
          </ListItem>
        </Link>
      </List>
    </div>
  );

  if (!localStorage.getItem("login")) {
    return <Navigate to="/login" />;
  }
  else {
    return (
      <div basename={props.basename}>
        <Dialog
          open={tampil_dialog_keluar}
          onClose={() => setTampilDialogKeluar(false)}
        >
          <DialogTitle>
            Konfirmasi
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Apakah anda yakin ingin keluar dari aplikasi?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setTampilDialogKeluar(false)} autoFocus>Tidak</Button>
            <Button
              onClick={() => {
                setTampilDialogKeluar(false);
                props.proses_keluar();
              }}
            >Ya</Button>
          </DialogActions>
        </Dialog>

        <Box
          sx={{
            backgroundColor: "#eff0f0",
            minHeight: '100vh',
            display: 'flex'
          }}
        >
          <CssBaseline />
          <AppBar position="fixed" open={open}
            sx={{
              backgroundColor: "#fe9400",
              // width: { sm: `calc(100% - ${drawerWidth}px)` },
              // ml: { sm: `${drawerWidth}px` },
            }}
          >
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{ mr: 2, ...(open && { display: 'none' }) }}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
                {menu}
              </Typography>

              <Button color="inherit"
                onClick={() => {
                  setTampilDialogKeluar(true);
                }}
              >
                Keluar
              </Button>
            </Toolbar>
          </AppBar>
          <Drawer
            sx={{
              // display: { xs: 'none', sm: 'block' },
              width: drawerWidth,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: drawerWidth,
                boxSizing: 'border-box',
              },
            }}
            variant="persistent"
            anchor="left"
            open={open}
          >
            <DrawerHeader>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </IconButton>
            </DrawerHeader>
            <Divider />
            {drawer}
          </Drawer>

          <Main open={open}>
            <DrawerHeader />

            <Routes>
              <Route path='/' exact
                render={(localProps) => {
                  pindah_menu("Beranda");

                  return (
                    <Navigate to="/beranda" />
                  );
                }}
              />

              <Route
                path="/beranda"
                element={
                  <Beranda aplikasi={props.aplikasi} />
                }
              />

              <Route
                path="/jurnal"
                element={
                  <Jurnal aplikasi={props.aplikasi} />
                }
              />

              <Route
                path="/favorit"
                element={
                  <Favorit aplikasi={props.aplikasi} />
                }
              />

              <Route
                path="/general_ledger"
                element={
                  <GeneralLedger aplikasi={props.aplikasi} />
                }
              />

              <Route
                path="/daftar_transaksi_pusat"
                element={
                  <DaftarTransaksi aplikasi={props.aplikasi} />
                }
              />

              <Route
                path="/laporan_keuangan_pusat"
                element={
                  <LapKeuangan aplikasi={props.aplikasi} />
                }
              />

              <Route
                path="/tracking_gl_pusat"
                element={
                  <TrackingGL aplikasi={props.aplikasi} />
                }
              />

              <Route
                path="/daftar_transaksi_gkr"
                element={
                  <DaftarTransaksiGkr aplikasi={props.aplikasi} />
                }
              />

              <Route
                path="/laporan_keuangan_gkr"
                element={
                  <LapKeuanganGkr aplikasi={props.aplikasi} />
                }
              />

              <Route
                path="/tracking_gl_gkr"
                element={
                  <TrackingGLGkr aplikasi={props.aplikasi} />
                }
              />

              <Route
                path="/daftar_transaksi_carwash"
                element={
                  <DaftarTransaksiCarwash aplikasi={props.aplikasi} />
                }
              />

              <Route
                path="/laporan_keuangan_carwash"
                element={
                  <LapKeuanganCarwash aplikasi={props.aplikasi} />
                }
              />

              <Route
                path="/tracking_gl_carwash"
                element={
                  <TrackingGLCarwash aplikasi={props.aplikasi} />
                }
              />

              <Route
                path="/daftar_transaksi_gym"
                element={
                  <DaftarTransaksiGym aplikasi={props.aplikasi} />
                }
              />

              <Route
                path="/laporan_keuangan_gym"
                element={
                  <LapKeuanganGym aplikasi={props.aplikasi} />
                }
              />

              <Route
                path="/tracking_gl_gym"
                element={
                  <TrackingGLGym aplikasi={props.aplikasi} />
                }
              />

              <Route
                path="/laporan_keuangan_konsolidasi"
                element={
                  <LapKeuanganKonsolidasi aplikasi={props.aplikasi} />
                }
              />

              <Route
                path="/laporan_penjualan"
                element={
                  <LapPenjualan aplikasi={props.aplikasi} />
                }
              />

              <Route
                path="/laporan_metode_bayar"
                element={
                  <LapMetodeBayar aplikasi={props.aplikasi} />
                }
              />

              <Route
                path="/laporan_belanja"
                element={
                  <LapBelanjaBahan aplikasi={props.aplikasi} />
                }
              />

              <Route
                path="/laporan_kehadiran"
                element={
                  <LapKehadiran aplikasi={props.aplikasi} />
                }
              />

              <Route
                path="/laporan_layanan"
                element={
                  <LapLayanan aplikasi={props.aplikasi} />
                }
              />

              <Route
                path="/laporan_pengguna"
                element={
                  <LapPengguna aplikasi={props.aplikasi} />
                }
              />

              <Route
                path="/laporan_karyawan"
                element={
                  <LapKaryawan aplikasi={props.aplikasi} />
                }
              />

              <Route
                path="/belanja"
                element={
                  <Belanja
                    aplikasi={props.aplikasi}
                  />
                }
              />

              <Route
                path="/penyesuaian_stok"
                element={
                  <PenyesuaianStok
                    aplikasi={props.aplikasi}
                  />
                }
              />

              <Route
                path="/pelanggan"
                element={
                  <Pelanggan aplikasi={props.aplikasi} />
                }
              />

              <Route
                path="/layanan"
                element={
                  <Layanan aplikasi={props.aplikasi} />
                }
              />

              <Route
                path="/pengguna"
                element={
                  <Pengguna aplikasi={props.aplikasi} file={props.file} />
                }
              />

              <Route
                path="/karyawan"
                element={
                  <Karyawan aplikasi={props.aplikasi} file={props.file} />
                }
              />

              <Route
                path="/metode_bayar"
                element={
                  <MetodeBayar aplikasi={props.aplikasi} file={props.file} />
                }
              />

              <Route
                path="/terima_saldo"
                element={
                  <TerimaSaldo aplikasi={props.aplikasi} />
                }
              />

              <Route
                path="/eom"
                element={
                  <Eom aplikasi={props.aplikasi} />
                }
              />

              <Route
                path="/pengaturan"
                element={
                  <Pengaturan aplikasi={props.aplikasi} file={props.file} />
                }
              />

              {/* <Route
              path="*"
              render={() => (
                <Tidak_Ditemukan />
              )}
            /> */}
            </Routes>
          </Main>
        </Box>
      </div>
    );
  }
}

export default Utama;