import React, { useState, useEffect, forwardRef } from 'react';

//komponen
import { styled } from '@mui/material/styles';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Stack from '@mui/material/Stack';

//gambar
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "rgba(108,122,224,255)",
        borderColor: "rgba(108,122,224,255)",
        color: "white"
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: "rgba(248,246,255,255)",
        borderColor: "rgba(248,246,255,255)"
    },
}));

function Pindah_Saldo(props, ref) {
    //state
    const [proses, setProses] = useState(false);
    const [tampil_notif, setTampilNotif] = useState(false);
    const [jenis_notif, setJenisNotif] = useState("success");
    const [isi_notif, setIsiNotif] = useState("");
    const [saldo, setSaldo] = useState(0);
    const [saldo_gkr, setSaldoGkr] = useState(0);
    const [saldo_carwash, setSaldoCarwash] = useState(0);
    const [saldo_gym, setSaldoGym] = useState(0);    
    const [data_obat, setDataObat] = useState([]);    
    const [cabang, setCabang] = useState("");
    const [waktu, setWaktu] = useState("");
    const [nominal, setNominal] = useState(0);
    const [tampil_dialog_terima, setTampilDialogTerima] = useState(false);
    const [tampil_dialog_tolak, setTampilDialogTolak] = useState(false);

    //fungsi
    useEffect(() => {
        fetch(props.aplikasi + '/pengaturan/tampil_pindah_saldo.php',
            {
                method: 'GET',
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setSaldo(data.saldo);
                    setSaldoGkr(data.saldo_gkr);
                    setSaldoCarwash(data.saldo_carwash);
                    setSaldoGym(data.saldo_gym);
                }
                else {
                    setSaldo(0);
                    setSaldoGkr(0);
                    setSaldoCarwash(0);
                    setSaldoGym(0);
                }
            })
            .catch(error => {
                setSaldo(0);
                setSaldoGkr(0);
                setSaldoCarwash(0);
                setSaldoGym(0);
            }
            );

        tampil_riwayat();
    }, [props.aplikasi]);

    const bersih = () => {
        fetch(props.aplikasi + '/pengaturan/tampil_pindah_saldo.php',
            {
                method: 'GET',
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setSaldo(data.saldo);
                    setSaldoGkr(data.saldo_gkr);
                    setSaldoCarwash(data.saldo_carwash);
                    setSaldoGym(data.saldo_gym);
                }
                else {
                    setSaldo(0);
                    setSaldoGkr(0);
                    setSaldoCarwash(0);
                    setSaldoGym(0);
                }
            })
            .catch(error => {
                setSaldo(0);
                setSaldoGkr(0);
                setSaldoCarwash(0);
                setSaldoGym(0);
            }
            );

        setNominal(0);
    }

    const tampil_riwayat = () => {
        fetch(props.aplikasi + '/pengaturan/tampil_permintaan.php',
            {
                method: 'GET',
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataObat(data.data);
                }
                else {
                    setDataObat([]);
                }
            })
            .catch(error => {
                setDataObat([]);
            }
            );
    }

    const format_rupiah = (angka) => {
        return (
            angka
                .toFixed(0)
                .replace(".", ",")
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
        )
    }

    const format_tanggal = (waktu, format) => {
        let tahun = waktu.substring(0, 4);
        let bulan = waktu.substring(5, 7);
        let tanggal = waktu.substring(8, 10);
        let jam = waktu.substring(11, 13);
        let menit = waktu.substring(14, 16);
        let detik = waktu.substring(17, 19);

        if (format === "short") {
            return tanggal + "-" + bulan + "-" + tahun;
        }
        else {
            return tanggal + "-" + bulan + "-" + tahun + " " + jam + ":" + menit + ":" + detik;
        }
    }

    const tutup_notif = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setTampilNotif(false);
    };

    const simpan = (varkonfirmasi) => {
        setProses(true);
        fetch(props.aplikasi + '/pengaturan/simpan_pindah_saldo.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_otorisasi: localStorage.getItem("id_pengguna"),
                        otorisasi: localStorage.getItem("nama"),
                        cabang: cabang,
                        waktu: waktu,
                        nominal: nominal,
                        konfirmasi: varkonfirmasi
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    bersih();
                    tampil_riwayat();
                    setJenisNotif("success");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    setProses(false);
                }
                else {
                    setJenisNotif("warning");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    setProses(false);
                }
            })
            .catch(error => {
                setJenisNotif("error");
                setIsiNotif("Terjadi kesalahan");
                setTampilNotif(true);
                setProses(false);
            }
            );
    }    

    return (
        <div>
            <Snackbar
                open={tampil_notif}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={tutup_notif}
            >
                <Alert
                    severity={jenis_notif}
                    variant="filled"
                    onClose={tutup_notif}
                    sx={{ width: '100%' }}
                >
                    {isi_notif}
                </Alert>
            </Snackbar>

            <Dialog
                open={tampil_dialog_terima}
                onClose={() => setTampilDialogTerima(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Konfirmasi Terima
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Apakah anda yakin ingin menerima kas ini?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        loadingPosition="start"
                        loading={proses}
                        onClick={() => setTampilDialogTerima(false)}
                        autoFocus
                    >
                        Tidak
                    </LoadingButton>
                    <LoadingButton
                        loadingPosition="start"
                        loading={proses}
                        onClick={() => {
                            setTampilDialogTerima(false);
                            simpan("A");
                        }}
                    >
                        Ya
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            <Dialog
                open={tampil_dialog_tolak}
                onClose={() => setTampilDialogTolak(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Konfirmasi Tolak
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Apakah anda yakin ingin menolak kas ini?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        loadingPosition="start"
                        loading={proses}
                        onClick={() => setTampilDialogTolak(false)}
                        autoFocus
                    >
                        Tidak
                    </LoadingButton>
                    <LoadingButton
                        loadingPosition="start"
                        loading={proses}
                        onClick={() => {
                            setTampilDialogTolak(false);
                            simpan("D");
                        }}
                    >
                        Ya
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            <Grid container>
                <Grid item xs={12} md={6}>
                    <Box
                        sx={{
                            backgroundColor: "#eff0f0",
                            width: "100%",
                            height: "100%"
                        }}
                    >
                        <Card
                            sx={{ margin: "10px", padding: "10px" }}
                        >
                            <Grid container spacing={1}>
                                {/* <Grid container item>
                                    <Grid item xs={4} sm={4} md={3} >
                                        <Typography variant="body2">
                                            Saldo Kas
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={8} sm={8} md={9}>
                                        <Typography variant="h4" align="right" sx={{ color: "red" }}>
                                            Rp. {format_rupiah(parseInt(saldo))}
                                        </Typography>
                                    </Grid>
                                </Grid> */}

                                <Grid container item>
                                    <Grid item xs={4} sm={4} md={3} >
                                        <Typography variant="body2">
                                            Saldo GKR
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={8} sm={8} md={9}>
                                        <Typography variant="h4" align="right" sx={{ color: "red" }}>
                                            Rp. {format_rupiah(parseInt(saldo_gkr))}
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid container item>
                                    <Grid item xs={4} sm={4} md={3} >
                                        <Typography variant="body2">
                                            Saldo Carwash
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={8} sm={8} md={9}>
                                        <Typography variant="h4" align="right" sx={{ color: "red" }}>
                                            Rp. {format_rupiah(parseInt(saldo_carwash))}
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid container item>
                                    <Grid item xs={4} sm={4} md={3} >
                                        <Typography variant="body2">
                                            Saldo Gym
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={8} sm={8} md={9}>
                                        <Typography variant="h4" align="right" sx={{ color: "red" }}>
                                            Rp. {format_rupiah(parseInt(saldo_gym))}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Card>
                    </Box>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Box
                        sx={{
                            backgroundColor: "#eff0f0",
                            width: "100%",
                            height: "100%"
                        }}
                    >
                        <Card
                            sx={{ margin: "10px", padding: "10px" }}
                        >
                            <Grid container spacing={1}>
                                <Grid container item>
                                    <Grid item xs={12} >
                                        <Typography variant="h6">
                                            Daftar Permintaan
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid container item>
                                    <Grid item xs={12} >
                                        <TableContainer component={Paper}>
                                            <Table stickyHeader size="small" aria-label="sticky table">
                                                <TableHead>
                                                    <TableRow>
                                                        <StyledTableCell align="center">No.</StyledTableCell>
                                                        <StyledTableCell align="center">Tgl. & Jam</StyledTableCell>
                                                        <StyledTableCell align="center">Nominal</StyledTableCell>
                                                        <StyledTableCell align="center">Pengirim</StyledTableCell>
                                                        <StyledTableCell align="center">Aksi</StyledTableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        data_obat.map((tampung, index) =>
                                                            <>
                                                                <StyledTableRow key={index}>
                                                                    <TableCell size="small" align="center">
                                                                        <Typography variant="body2">
                                                                            {index + 1}
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell size="small" align='center'>
                                                                        <Typography variant="body2">
                                                                            {format_tanggal(tampung.waktu_entry, "long")}
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell size="small" align="right">
                                                                        <Typography variant="body2">
                                                                            {format_rupiah(parseFloat(tampung.nominal))}
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell size="small" align='center'>
                                                                        <Typography variant="body2">
                                                                            {tampung.cabang + " (" + tampung.pengirim + ")"}
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell size="small" align='center'>
                                                                        <Stack spacing={1}>
                                                                            <LoadingButton
                                                                                loadingPosition="start"
                                                                                variant="contained"
                                                                                color="primary"
                                                                                startIcon={<CheckIcon />}
                                                                                sx={{ borderRadius: "5em", backgroundColor: "green" }}
                                                                                loading={proses}
                                                                                onClick={() => {
                                                                                    setCabang(tampung.cabang);
                                                                                    setWaktu(tampung.waktu_entry);
                                                                                    setNominal(tampung.nominal);
                                                                                    setTampilDialogTerima(true);
                                                                                }}
                                                                            >
                                                                                <Typography variant="body2" noWrap={true}>Terima</Typography>
                                                                            </LoadingButton>

                                                                            <LoadingButton
                                                                                loadingPosition="start"
                                                                                variant="contained"
                                                                                color="primary"
                                                                                startIcon={<ClearIcon />}
                                                                                sx={{ borderRadius: "5em", backgroundColor: "red" }}
                                                                                loading={proses}
                                                                                onClick={() => {
                                                                                    setCabang(tampung.cabang);
                                                                                    setWaktu(tampung.waktu_entry);
                                                                                    setNominal(tampung.nominal);
                                                                                    setTampilDialogTolak(true);
                                                                                }}
                                                                            >
                                                                                <Typography variant="body2" noWrap={true}>Tolak</Typography>
                                                                            </LoadingButton>
                                                                        </Stack>
                                                                    </TableCell>
                                                                </StyledTableRow>
                                                            </>
                                                        )
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Card>
                    </Box>
                </Grid>
            </Grid>
        </div>
    );
}

export default forwardRef(Pindah_Saldo);