import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";

//form
import Login from './login';
import Utama from './utama';

// const url_aplikasi = "http://localhost/pusat_api";
// const url_file = "http://localhost/pusat_file";
// const basename = "";

const url_aplikasi = "https://office.keisharesto.com/pusat_api";
const url_file = "https://office.keisharesto.com/pusat_file";
const basename = "";

function App() {
  return (
    <BrowserRouter basename={basename}>
      <Routes>
        <Route
          path="/login"
          element={
            <Login
              aplikasi={url_aplikasi}
              file={url_file}
            />
          }
        />
        <Route
          path="*"
          element={
            <Utama
              aplikasi={url_aplikasi}
              file={url_file}
            />
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;