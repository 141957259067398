import React, { useState, useEffect, forwardRef } from 'react';

//komponen
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';

//gambar
import AddOutlined from '@mui/icons-material/AddOutlined';
import SaveOutlined from '@mui/icons-material/SaveOutlined';
import CloseOutlined from '@mui/icons-material/CloseOutlined';
import SearchOutlined from '@mui/icons-material/SearchOutlined';
import DeleteIcon from '@mui/icons-material/Delete';

function Favorit(props, ref) {
    //state
    const [tampil_notif, setTampilNotif] = useState(false);
    const [jenis_notif, setJenisNotif] = useState("success");
    const [isi_notif, setIsiNotif] = useState("");
    const [tampil_dialog, setTampilDialog] = useState(false);
    const [sumber_favorit, setSumberFavorit] = useState([]);
    const [data_favorit, setDataFavorit] = useState([]);
    const [id_jurnal, setIdJurnal] = useState("");
    const [nama, setNama] = useState("");
    const [data_gl, setDataGl] = useState([]);
    const [debit, setDebit] = useState(null);
    const [input_debit, setInputDebit] = useState("");
    const [kredit, setKredit] = useState(null);
    const [input_kredit, setInputKredit] = useState("");
    const [tampil_dialog_hapus, setTampilDialogHapus] = useState(false);

    //fungsi
    useEffect(() => {
        fetch(props.aplikasi + '/akuntansi_pusat/tampil_favorit.php',
            {
                method: 'GET',
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setSumberFavorit(data.data);
                    setDataFavorit(data.data);
                }
                else {
                    setSumberFavorit([]);
                    setDataFavorit([]);
                }
            })
            .catch(error => {
                setSumberFavorit([]);
                setDataFavorit([]);
            }
            );

        fetch(props.aplikasi + '/akuntansi_pusat/tampil_gl.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        status: ""
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataGl(data.data);
                }
                else {
                    setDataGl([]);
                }
            })
            .catch(error => {
                setDataGl([]);
            }
            );
    }, [props.aplikasi]);

    const tutup_notif = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setTampilNotif(false);
    };

    const tampil_favorit = () => {
        fetch(props.aplikasi + '/akuntansi_pusat/tampil_favorit.php',
            {
                method: 'GET',
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setSumberFavorit(data.data);
                    setDataFavorit(data.data);
                }
                else {
                    setSumberFavorit([]);
                    setDataFavorit([]);
                }
            })
            .catch(error => {
                setSumberFavorit([]);
                setDataFavorit([]);
            }
            );
    }

    const cari = (event) => {
        var cari = (event.target.value).toLowerCase();
        var awal = 1;
        var akhir = sumber_favorit.length;

        var posisi = 0;
        var tampung = [];
        while (awal <= akhir) {
            var hasil = ((sumber_favorit[awal - 1]["keterangan"]).toLowerCase()).indexOf(cari);
            if (hasil === -1) {
                hasil = ((sumber_favorit[awal - 1]["keterangan"]).toLowerCase()).indexOf(cari);
            }

            if (hasil !== -1) {
                tampung[posisi] = sumber_favorit[awal - 1];
                posisi++;
            }
            awal++;
        }

        setDataFavorit(tampung);
    }

    const bersih_input_jurnal = () => {
        setIdJurnal("");
        setNama("");
        setDebit(null);
        setKredit(null);
    }

    const simpan = () => {
        if (nama.trim() === "") {
            setJenisNotif("warning");
            setIsiNotif("Nama jurnal favorit wajib diisi");
            setTampilNotif(true);
            document.getElementById("nama").focus();
            return;
        }

        if (debit === null) {
            setJenisNotif("warning");
            setIsiNotif("Debit wajib diisi");
            setTampilNotif(true);
            document.getElementById("debit").focus();
            return;
        }

        if (kredit === null) {
            setJenisNotif("warning");
            setIsiNotif("Kredit wajib diisi");
            setTampilNotif(true);
            document.getElementById("kredit").focus();
            return;
        }

        if (id_jurnal === "") {
            fetch(props.aplikasi + '/akuntansi_pusat/simpan_favorit.php',
                {
                    method: 'POST',
                    body: JSON.stringify
                        ({
                            permintaan: "simpan",
                            id_jurnal: "",
                            nama: nama,
                            debit: debit.id_gl,
                            kredit: kredit.id_gl,
                        }),
                    headers:
                    {
                        "Content-type": "application/json; charset=UTF-8"
                    }
                })
                .then(response => response.json())
                .then(data => {
                    if (data.kode === 1) {
                        bersih_input_jurnal();
                        tampil_favorit();
                        setJenisNotif("success");
                        setIsiNotif(data.pesan);
                        setTampilNotif(true);
                        document.getElementById("nama").focus();
                    }
                    else {
                        tampil_favorit();
                        setJenisNotif("warning");
                        setIsiNotif(data.pesan);
                        setTampilNotif(true);
                        document.getElementById("nama").focus();
                    }
                })
                .catch(error => {
                    tampil_favorit();
                    setJenisNotif("error");
                    setIsiNotif("Terjadi kesalahan");
                    setTampilNotif(true);
                    document.getElementById("nama").focus();
                }
                );
        }
        else {
            fetch(props.aplikasi + '/akuntansi_pusat/simpan_favorit.php',
                {
                    method: 'POST',
                    body: JSON.stringify
                        ({
                            permintaan: "perbarui",
                            id_jurnal: id_jurnal,
                            nama: nama,
                            debit: debit.id_gl,
                            kredit: kredit.id_gl,
                        }),
                    headers:
                    {
                        "Content-type": "application/json; charset=UTF-8"
                    }
                })
                .then(response => response.json())
                .then(data => {
                    if (data.kode === 1) {
                        tampil_favorit();
                        setJenisNotif("success");
                        setIsiNotif(data.pesan);
                        setTampilNotif(true);
                        document.getElementById("nama").focus();
                    }
                    else {
                        tampil_favorit();
                        setJenisNotif("warning");
                        setIsiNotif(data.pesan);
                        setTampilNotif(true);
                        document.getElementById("nama").focus();
                    }
                })
                .catch(error => {
                    tampil_favorit();
                    setJenisNotif("error");
                    setIsiNotif("Terjadi kesalahan");
                    setTampilNotif(true);
                    document.getElementById("nama").focus();
                }
                );
        }
    }

    const hapus = () => {
        fetch(props.aplikasi + '/akuntansi_pusat/hapus_favorit.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_jurnal: id_jurnal
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    bersih_input_jurnal();
                    tampil_favorit();
                    setJenisNotif("success");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    document.getElementById("nama").focus();
                }
                else {
                    tampil_favorit();
                    setJenisNotif("warning");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    document.getElementById("nama").focus();
                }
            })
            .catch(error => {
                tampil_favorit();
                setJenisNotif("error");
                setIsiNotif("Terjadi kesalahan");
                setTampilNotif(true);
                document.getElementById("nama").focus();
            }
            );
    }

    return (
        <div>
            <Snackbar
                open={tampil_notif}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={tutup_notif}
            >
                <Alert
                    severity={jenis_notif}
                    variant="filled"
                    onClose={tutup_notif}
                    sx={{ width: '100%' }}
                >
                    {isi_notif}
                </Alert>
            </Snackbar>

            <Box
                sx={{
                    backgroundColor: "#eff0f0",
                    width: "100%",
                    height: "100%"
                }}
            >
                <Fab
                    color="primary"
                    sx={{
                        background: "#37ce9b",
                        border: "#37ce9b",
                        margin: 0,
                        top: "auto",
                        right: 20,
                        bottom: 20,
                        left: "auto",
                        position: "fixed",
                        zIndex: "99"
                    }}
                    onClick={() => {
                        bersih_input_jurnal();
                        setTampilDialog(true);
                    }}
                >
                    <AddOutlined />
                </Fab>

                <Card
                    sx={{ padding: "10px" }}
                >
                    <TextField
                        id="input-with-icon-textfield"
                        label="Cari berdasarkan nama favorit"
                        variant="outlined"
                        size="small"
                        fullWidth
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchOutlined />
                                </InputAdornment>
                            ),
                        }}
                        onChange={cari}
                    />
                </Card>

                <Grid container spacing={1} sx={{ padding: "10px" }}>
                    {data_favorit.map((data_favorit) => {
                        return (
                            <Grid
                                item xs={12} sm={6} md={4} lg={3}
                                key={data_favorit.id_jurnal}
                                onClick={() => {
                                    bersih_input_jurnal();
                                    setIdJurnal(data_favorit.id_jurnal);
                                    setNama(data_favorit.keterangan);

                                    var awal = 1;
                                    var akhir = data_gl.length;
                                    while (awal <= akhir) {
                                        if (data_gl[awal - 1]["id_gl"] === data_favorit.gl_debit) {
                                            setDebit(data_gl[awal - 1]);
                                            break;
                                        }
                                        awal++;
                                    }

                                    awal = 1;
                                    akhir = data_gl.length;
                                    while (awal <= akhir) {
                                        if (data_gl[awal - 1]["id_gl"] === data_favorit.gl_kredit) {
                                            setKredit(data_gl[awal - 1]);
                                            break;
                                        }
                                        awal++;
                                    }

                                    setTampilDialog(true);
                                }}
                            >
                                <Card>
                                    <List>
                                        <ListItem button>
                                            <ListItemText
                                                primary={
                                                    <Typography variant="body1" noWrap>{data_favorit.keterangan}</Typography>
                                                }
                                                secondary={
                                                    <div>
                                                        <Typography variant="body2" noWrap>Debit : {data_favorit.gl_debit} - {data_favorit.debit}</Typography>
                                                        <Typography variant="body2" noWrap>Kredit : {data_favorit.gl_kredit} - {data_favorit.kredit}</Typography>
                                                    </div>
                                                }
                                            >
                                            </ListItemText>
                                        </ListItem>
                                    </List>
                                </Card>
                            </Grid>
                        );
                    })}
                </Grid>
            </Box>

            <Dialog
                open={tampil_dialog}
                onClose={() => setTampilDialog(false)}
            >
                <DialogTitle>Input Data Jurnal Favorit</DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        <Grid container item spacing={1}>
                            <Grid container item>
                                <Grid item xs={4} sm={4} md={3}>
                                    <Typography variant="body2">
                                        Nama
                                    </Typography>
                                </Grid>

                                <Grid item xs={8} sm={8} md={9}>
                                    <TextField
                                        id="nama"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        multiline
                                        value={nama}
                                        onChange={(event) => setNama(event.target.value)}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={4} sm={4} md={3}>
                                    <Typography variant="body2">
                                        Debit
                                    </Typography>
                                </Grid>

                                <Grid item xs={8} sm={8} md={9}>
                                    <Autocomplete
                                        id="debit"
                                        options={data_gl}
                                        getOptionLabel={(option) => option.id_gl + " - " + option.gl}
                                        inputValue={input_debit}
                                        value={debit}
                                        onInputChange={(e, nilai) => setInputDebit(nilai)}
                                        onChange={(e, nilai) => setDebit(nilai)}
                                        renderInput={(params) => <TextField {...params} key={params.id_gl} variant="outlined" size="small" />}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={4} sm={4} md={3}>
                                    <Typography variant="body2">
                                        Kredit
                                    </Typography>
                                </Grid>

                                <Grid item xs={8} sm={8} md={9}>
                                    <Autocomplete
                                        id="kredit"
                                        options={data_gl}
                                        getOptionLabel={(option) => option.id_gl + " - " + option.gl}
                                        inputValue={input_kredit}
                                        value={kredit}
                                        onInputChange={(e, nilai) => setInputKredit(nilai)}
                                        onChange={(e, nilai) => setKredit(nilai)}
                                        renderInput={(params) => <TextField {...params} key={params.id_gl} variant="outlined" size="small" />}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<SaveOutlined />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em" }}
                        onClick={() => simpan()}
                    >                        
                        <Typography variant="body2" noWrap={true}>{id_jurnal === "" ? "Simpan" : "Perbarui"}</Typography>
                    </Button>

                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<DeleteIcon />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "red" }}
                        onClick={() => setTampilDialogHapus(true)}
                    >
                        <Typography variant="body2" noWrap={true}>Hapus</Typography>
                    </Button>

                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<CloseOutlined />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#555555" }}
                        onClick={() => setTampilDialog(false)}
                    >
                        <Typography variant="body2" noWrap={true}>Tutup</Typography>
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={tampil_dialog_hapus}
                onClose={() => setTampilDialogHapus(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Konfirmasi Hapus Metode Bayar
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Apakah anda yakin ingin menghapus data ini?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setTampilDialogHapus(false)} autoFocus>Tidak</Button>
                    <Button
                        onClick={() => {
                            setTampilDialogHapus(false);
                            setTampilDialog(false);
                            hapus();
                        }}
                    >Ya</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default forwardRef(Favorit);