import React, { useState, useEffect, forwardRef } from 'react';

//komponen
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';

//gambar
import SaveOutlined from '@mui/icons-material/SaveOutlined';

function Jurnal(props, ref) {
    //state
    const [tampil_notif, setTampilNotif] = useState(false);
    const [jenis_notif, setJenisNotif] = useState("success");
    const [isi_notif, setIsiNotif] = useState("");
    const [cara_input, setCaraInput] = useState("favorit");
    const [data_transaksi, setDataTransaksi] = useState([]);
    const [transaksi, setTransaksi] = useState(null);
    const [input_transaksi, setInputTransaksi] = useState("");
    const [data_gl, setDataGl] = useState([]);
    const [debit, setDebit] = useState(null);
    const [input_debit, setInputDebit] = useState("");
    const [kredit, setKredit] = useState(null);
    const [input_kredit, setInputKredit] = useState("");
    const [no_reff, setNoReff] = useState("");
    const [nominal, setNominal] = useState(0);
    const [keterangan, setKeterangan] = useState("");

    //fungsi
    useEffect(() => {
        fetch(props.aplikasi + '/akuntansi_pusat/tampil_favorit.php',
            {
                method: 'GET',
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataTransaksi(data.data);
                }
                else {
                    setDataTransaksi([]);
                }
            })
            .catch(error => {
                setDataTransaksi([]);
            }
            );

        fetch(props.aplikasi + '/akuntansi_pusat/tampil_gl.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        status: ""
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataGl(data.data);
                }
                else {
                    setDataGl([]);
                }
            })
            .catch(error => {
                setDataGl([]);
            }
            );
    }, [props.aplikasi]);

    const ubah_nominal = (event) => {
        // const re = /^[0-9\b]+$/;
        const re = /^[+-]?\d*(?:[.]\d*)?$/;
        if (re.test(event.target.value) === false && event.target.value !== "") {
            return;
        }
        setNominal(event.target.value);
    }

    const perbaiki_nominal = (event) => {
        if (parseFloat(event.target.value) > 0) {
            setNominal(event.target.value);
        }
        else {
            setNominal(0);
        }
    }

    const format_rupiah = (angka) => {
        return (
            angka
                .toFixed(2)
                .replace(".", ",")
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
        )
    }

    const tutup_notif = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setTampilNotif(false);
    };

    const simpan = () => {
        if (cara_input === "favorit" && transaksi === null) {
            setJenisNotif("warning");
            setIsiNotif("Nama transaksi wajib diisi");
            setTampilNotif(true);
            document.getElementById("transaksi").focus();
            return;
        }

        if (debit === null) {
            setJenisNotif("warning");
            setIsiNotif("Debit wajib diisi");
            setTampilNotif(true);
            document.getElementById("debit").focus();
            return;
        }

        if (kredit === null) {
            setJenisNotif("warning");
            setIsiNotif("Kredit wajib diisi");
            setTampilNotif(true);
            document.getElementById("kredit").focus();
            return;
        }

        if (no_reff.trim() === "") {
            setJenisNotif("warning");
            setIsiNotif("No. Reff wajib diisi");
            setTampilNotif(true);
            document.getElementById("no_reff").focus();
            return;
        }

        if (parseInt(nominal) === 0) {
            setJenisNotif("warning");
            setIsiNotif("Nominal wajib diisi");
            setTampilNotif(true);
            document.getElementById("nominal").focus();
            return;
        }

        if (keterangan.trim() === "") {
            setJenisNotif("warning");
            setIsiNotif("Keterangan wajib diisi");
            setTampilNotif(true);
            document.getElementById("keterangan").focus();
            return;
        }

        fetch(props.aplikasi + '/akuntansi_pusat/simpan_jurnal.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        debit: debit.id_gl,
                        kredit: kredit.id_gl,
                        no_reff: no_reff,
                        nominal: nominal,
                        keterangan: keterangan,
                        id_pengguna: localStorage.getItem("id_pengguna")
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setTransaksi(null);
                    setDebit(null);
                    setKredit(null);
                    setNoReff("");
                    setNominal(0);
                    setKeterangan("");
                    setJenisNotif("success");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                }
                else {
                    setJenisNotif("warning");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                }
            })
            .catch(error => {
                setJenisNotif("error");
                setIsiNotif("Terjadi kesalahan");
                setTampilNotif(true);
            }
            );
    }

    return (
        <div>
            <Snackbar
                open={tampil_notif}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={tutup_notif}
            >
                <Alert
                    severity={jenis_notif}
                    variant="filled"
                    onClose={tutup_notif}
                    sx={{ width: '100%' }}
                >
                    {isi_notif}
                </Alert>
            </Snackbar>

            <Box
                sx={{
                    backgroundColor: "#eff0f0",
                    width: "100%",
                    height: "100%"
                }}
            >
                <Card
                    sx={{ margin: "10px", padding: "10px" }}
                >
                    <Grid container spacing={1}>
                        <Grid container item>
                            <Grid item xs={4} sm={4} md={3} >
                                <Typography variant="body2">
                                    Cara Input
                                </Typography    >
                            </Grid>

                            <Grid item xs={8} sm={8} md={9}>
                                <RadioGroup row
                                    value={cara_input}
                                    onChange={(e) => {
                                        setCaraInput(e.target.value);
                                        setTransaksi(null);
                                        setInputDebit("");
                                        setDebit(null);
                                        setInputKredit("");
                                        setKredit(null);
                                    }}
                                >
                                    <FormControlLabel value="favorit" control={<Radio />} label="Favorit" />
                                    <FormControlLabel value="manual" control={<Radio />} label="Manual" />
                                </RadioGroup>
                            </Grid>
                        </Grid>

                        <Grid container item>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                        </Grid>

                        <Grid container item
                            sx={
                                cara_input === "favorit" ? {} : { display: "none" }
                            }
                        >
                            <Grid item xs={4} sm={4} md={3}>
                                <Typography variant="body2">
                                    Nama Transaksi
                                </Typography>
                            </Grid>

                            <Grid item xs={8} sm={8} md={9}>
                                <Autocomplete
                                    id="transaksi"
                                    options={data_transaksi}
                                    getOptionLabel={(option) => option.keterangan}
                                    inputValue={input_transaksi}
                                    value={transaksi}
                                    onInputChange={(e, nilai) => setInputTransaksi(nilai)}
                                    onChange={(e, nilai) => {
                                        setTransaksi(nilai);

                                        if (nilai !== null) {
                                            var gl_debit = nilai.gl_debit;
                                            var gl_kredit = nilai.gl_kredit;

                                            var awal = 1;
                                            var akhir = data_gl.length;
                                            while (awal <= akhir) {
                                                if (data_gl[awal - 1]["id_gl"] === gl_debit) {
                                                    setDebit(data_gl[awal - 1]);
                                                    break;
                                                }
                                                awal++;
                                            }

                                            awal = 1;
                                            akhir = data_gl.length;
                                            while (awal <= akhir) {
                                                if (data_gl[awal - 1]["id_gl"] === gl_kredit) {
                                                    setKredit(data_gl[awal - 1]);
                                                    break;
                                                }
                                                awal++;
                                            }
                                        }
                                        else {
                                            setDebit(null);
                                            setKredit(null);
                                        }
                                    }}
                                    renderInput={(params) => <TextField {...params} key={params.id_jurnal} variant="outlined" size="small" />}
                                />
                            </Grid>
                        </Grid>

                        <Grid container item>
                            <Grid item xs={4} sm={4} md={3}>
                                <Typography variant="body2">
                                    Debit
                                </Typography>
                            </Grid>

                            <Grid item xs={8} sm={8} md={9}>
                                <Autocomplete
                                    id="debit"
                                    disabled={cara_input === "favorit" ? true : false}
                                    options={data_gl}
                                    getOptionLabel={(option) => option.id_gl + " - " + option.gl}
                                    inputValue={input_debit}
                                    value={debit}
                                    onInputChange={(e, nilai) => setInputDebit(nilai)}
                                    onChange={(e, nilai) => setDebit(nilai)}
                                    renderInput={(params) => <TextField {...params} key={params.id_gl} variant="outlined" size="small" />}
                                />
                            </Grid>
                        </Grid>

                        <Grid container item>
                            <Grid item xs={4} sm={4} md={3}>
                                <Typography variant="body2">
                                    Kredit
                                </Typography>
                            </Grid>

                            <Grid item xs={8} sm={8} md={9}>
                                <Autocomplete
                                    id="kredit"
                                    disabled={cara_input === "favorit" ? true : false}
                                    options={data_gl}
                                    getOptionLabel={(option) => option.id_gl + " - " + option.gl}
                                    inputValue={input_kredit}
                                    value={kredit}
                                    onInputChange={(e, nilai) => setInputKredit(nilai)}
                                    onChange={(e, nilai) => setKredit(nilai)}
                                    renderInput={(params) => <TextField {...params} key={params.id_gl} variant="outlined" size="small" />}
                                />
                            </Grid>
                        </Grid>

                        <Grid container item>
                            <Grid item xs={4} sm={4} md={3}>
                                <Typography variant="body2">
                                    No. Reff
                                </Typography>
                            </Grid>

                            <Grid item xs={8} sm={8} md={9}>
                                <TextField
                                    id="no_reff"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    value={no_reff}
                                    onChange={(e) => setNoReff(e.target.value)}
                                />
                            </Grid>
                        </Grid>

                        <Grid container item>
                            <Grid item xs={4} sm={4} md={3}>
                                <Typography variant="body2">
                                    Nominal
                                </Typography>
                            </Grid>

                            <Grid container item spacing={1} xs={8} sm={8} md={9}>
                                <Grid item xs={12} sm={12} md={6}>
                                    <TextField
                                        id="nominal"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        value={nominal}
                                        onChange={(e) => ubah_nominal(e)}
                                        onBlur={(e) => perbaiki_nominal(e)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <Typography variant="h4" align="right" sx={{ color: "red" }}>
                                        Rp. {format_rupiah(parseFloat(nominal))}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid container item>
                            <Grid item xs={4} sm={4} md={3}>
                                <Typography variant="body2">
                                    Keterangan
                                </Typography>
                            </Grid>

                            <Grid item xs={8} sm={8} md={9}>
                                <TextField
                                    id="keterangan"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    value={keterangan}
                                    onChange={(e) => setKeterangan(e.target.value)}
                                />
                            </Grid>
                        </Grid>

                        <Grid container item>
                            <Grid item xs={4} sm={4} md={3}>

                            </Grid>

                            <Grid item xs={8} sm={8} md={9}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<SaveOutlined />}
                                    sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em" }}
                                    onClick={() => simpan()}
                                >
                                    <Typography variant="body2" noWrap={true}>Simpan</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>
            </Box>
        </div>
    );
}

export default forwardRef(Jurnal);